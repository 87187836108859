.App {
  text-align: center;
  background-image: linear-gradient(10deg, white 70%, #eeedecf5 calc(70% + 2px));
  background-attachment: fixed;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.topHeader {
  padding: 30px;
}

.topHeader h1 {
  font-family: 'MrsSaintDelafield'; 
  position: sticky;
}
.topHeader h5 {
  font-family: 'CormorantSC';
}
.topHeader a {
  color: black;
}
.topHeader a:hover {
  color: black;
  text-decoration: none;
}

.Home {
  font-family: 'CormorantSC';
}

.Home Row {
  margin-top: 40px;
}

.homecard {
  opacity: 0.9;
}

.topHeaderBlack {
      margin-top: 36px;
      transition: all 0.8s ease; 
      margin-right: -20px;
      margin-left: -20px;
}
.topHeaderBlack:hover {
      transform: scale(1.1);
      background-color:white; 
      color: black;
}
.topHeaderBlack h4 {    
      color: black;
      font-size: 26px;
}

@media screen and (min-width: 320px) {
  .topHeaderWhite h4 {
    font-size: calc(16px + 6 * ((100vw - 320px) / 680));
  }
}

@media screen and (min-width: 320px) {
  .topHeaderBlack h4 {
    font-size: calc(16px + 6 * ((100vw - 320px) / 680));
  }
}

.aboutHello {
  font-family: 'CormorantSC';
  margin-top: 30px;
  text-align: left;
  text-decoration: none;
}

.aboutPicture {
  margin: 30px;
  border-radius: 50%;
}

.input-group-btn a {
  background-color: gray;
  border-radius: 5px;
  color: white;
  padding: 10px;
  transition: all .2s ease-in-out
}
.input-group-btn {
  transition: all .2s ease-in-out
}
.input-group-btn :hover { 
  color:white;
  transform: scale(1.1); 
}

.contact {
  font-family: CormorantSC;
  padding-top: 50px;
}

.socialLinks {
  font-family: CormorantSC;
justify-content: center;
}
.socialLinks a {
  color:black;
}

.socialLinks :hover {
  color:rgb(175, 172, 172);
 
}

.Form {
  width: 50%;
  margin: auto;
}
.previousWork {
  box-shadow: 0px 3px 15px rgba(0,0,0,0.2);
  margin-top:50px;
}

/* CSS for the hover text on past project page */

.image{
  transition: 3s ease;
  backface-visibility: hidden;
  opacity: 1;
}

.middle {
  transition: 2s ease;
  opacity: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  text-align: center;
}

.previousWork:hover .image {
  opacity: 0.3;
  transition: 5s ease;
}

.previousWork:hover .middle {
  opacity: 1;
}

.text {
  background-color: rgb(102, 101, 101);
  color: white;
  font-size: 16px;
  padding: 16px 32px;
  font-family: CormorantSC;
}

.homeCard:hover .image {
  opacity: 0.3;
  transition: .2s ease;
}

.homeCard:hover .middleHome {
  opacity: 1;
}

.hometext {
  color:black;
  margin-top: 50%;
  font-family: MrsSaintDelafield;
}

.hometext h4 {
 font-size: calc(20px + (50 - 20) * ((100vw - 300px) / (1600 - 300)));;
}


.middleHome {
  transition: 0.2s;
  opacity: 0;
  position: absolute;
  text-align: center;
}

.footer {
 padding:30px;
}

.FadeItems {
  animation: fadeInAnimation ease 2s; 
  animation-iteration-count: 1; 
  animation-fill-mode: forwards; 
}
@keyframes fadeInAnimation { 
  0% { 
      opacity: 0; 
  } 
  100% { 
      opacity: 1; 
  } 
} 

ul {
  
  text-align: left;
}
.youtubeLink {
  color: white;
}

.youtubeLink:hover {
  color:black;
  background-color: white;
  text-decoration: none;
}

.projectHeader {
  font-family: Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
  padding-top: 20px;
}

.navlink  {
  color: rgb(99, 99, 99);
  font-family: Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
  width: 100%;
  list-style-type: none;
}

.navlink:hover {
  color: black;
}

.Navigation {
  justify-content: center;
  max-width: 480px;
  margin: 0 auto;
  list-style-type: none;
}

.navitem {
  list-style-type: none;
}
